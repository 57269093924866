<template>
  <div>
    <v-alert v-show="show_msg" :type="msg_type">
      {{ errors }}
    </v-alert>
    <div class="card">
      <div class="card-header">
        Categorias
        <b-button class="float-right" v-if="$route.name != router_actions.route_new" @click="novo" variant="primary">Nova</b-button>
      </div>
      <div class="card-body">
        <b-table :fields="column" striped hover :items="items">
          <template #cell(actions)="row">
            <b-button size="sm" variant="primary" @click="editCat(row.item.id)">Editar</b-button>
            <b-button size="sm" variant="danger" @click="deletarCat(row.item.id, row.index)">Deletar</b-button>
          </template>
        </b-table>  
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
//import Categoria from "./Fornecedores/Categoria.vue";
export default {
  name: "categorias",
  data() {
    return {
      router_actions: {
        route_new: 'categorias-new',
        route_edit: 'categorias-edit'
      },
      column: [
        {
          key: "id",
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
          label: "Id da Categoria",
        },
        {
          key: "cat_title",
          sortable: true,
          label: "Nome",
        },
        {
          key: "cat_description",
          sortable: true,
          label: "Descrição",
        },
        {
          key: "actions",
          sortable: false,
          label: "Acões",
        },
      ],
      items: null,
      show_msg: false,
      msg_type: null,
      errors: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/" },
      { title: "Listagem de Categorias", route: '/categorias/list/' }
    ]);
    ApiService.get("categories")
      .then(({ data }) => {
        this.items = data;

        this.msg_type = "success";
        this.errors = data.message;
        //this.show_msg = true;
      })
      .catch(({ response }) => {
        this.errors = response.data.errors;
        this.msg_type = "error";
        this.show_msg = true;
      });
  },
  methods: {
    novo() {
      this.$router.push( {name: this.router_actions.route_new})
    },
    editCat: function (id) {
      this.$router.push('/categorias/edit/'+ id);
    },

    deletarCat: function (id, index) {
      ApiService.delete("categories/"+id)
        .then(({ data }) => {
          this.errors = data.message;
          this.msg_type = "success";
          this.show_msg = true;
          this.items.splice(index,1);
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.msg_type = "error";
          this.show_msg = true;
        });
    },
  },
  //components: {
  //  Categoria
  //}
};
</script>
